
  import { Component } from 'vue-property-decorator'
  import { MissingDetailsFields } from '@/errors'
  import { getObjectValueByPath } from '@/utils/vuetify/helpers'
  import { Expandable } from '@/components/toolkit/details/row/custom/Expandable'
  import dayjs from 'dayjs'
  import { Person } from '@/entities/persons'

@Component
  export default class ExpandablePerson extends Expandable {
    get header () {
      const { value } = this
      return value.fullName
    }

    get basicData () {
      const { fields } = this
      const data = []
      if (!fields?.length) throw new MissingDetailsFields()

      const { value } = this

      if (!value || !(value instanceof Person)) {
        return []
      }

      fields.forEach(({ label, path, transform }) => {
        if (transform) {
          data.push({
            fName: label,
            fValue: this.transformData(transform, getObjectValueByPath(value, path)),
          })
        } else {
          if (label === 'Edad') {
            if (value.type.name === 'natural_person') {
              data.push({
                fName: label,
                fValue: dayjs().diff(dayjs(value.birthDate), 'year'),
              })
            } else {
              data.push({
                fName: 'Antiguedad',
                fValue: dayjs().diff(dayjs(value.birthDate), 'year'),
              })
            }
          } else if (label === 'Fecha de nacimiento') {
            if (value.type.name === 'natural_person') {
              data.push({
                fName: label,
                fValue: getObjectValueByPath(value, path),
              })
            } else {
              data.push({
                fName: 'Fecha de constitución',
                fValue: getObjectValueByPath(value, path),
              })
            }
          } else {
            data.push({
              fName: label,
              fValue: getObjectValueByPath(value, path),
            })
          }
        }
      })

      return data.filter(({ fValue }) => fValue)
    }
  }
